import React from 'react';
import Layout from '../components/Layout';

const PayOfflinePage = () => {
    return (
        <Layout>
            <div className="container-fluid p-0" id="page-top">
                <section
                    className="resume-section p-3 p-lg-5 d-flex align-items-center"
                >
                    <div className="w-100">
                        <h1 className="mb-0">Give <span className="text-primary">Today</span>
                        </h1>
                        <h2 className="mb-5">Lambda Iota Alumni Association</h2>

                        <p className="mb-5">Any and every gift is welcome!</p>

                        <p className="mb-5">Paypal:<br />
                            Click the button below to make a donation via PayPal. This will accept both one-time and recurring
                            payments.<br /><br />
                            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                                <input type="hidden" name="cmd" value="_donations" />
                                <input type="hidden" name="business" value="lambdaiota.alumni.treasurer@gmail.com" />
                                <input type="hidden" name="currency_code" value="USD" />
                                <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0"
                                    name="submit" title="PayPal - The safer, easier way to pay online!"
                                    alt="Donate with PayPal button" />
                                <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                            </form>
                        </p>

                        <p className="mb-5">For those wanting to donate via ACH, a check payment, or arrange other payment means not
                            offered here, please contact:<br />
                            Matt Buchanan, Treasurer<br />
                            <a href="mailto:lambdaiota.alumni.treasurer@gmail.com">lambdaiota.alumni.treasurer@gmail.com</a></p>
                    </div>
                </section>
            </div>
        </Layout>
    )
};

export default PayOfflinePage;
